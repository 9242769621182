var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-page',{staticClass:"accountPages"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"page-title hide-in-mobile"},[_c('div',{staticClass:"page-breadcrumb"},[_c('router-link',{attrs:{"to":"/account"}},[_c('SvgIcon',{attrs:{"icon":"back-arrow-line"}}),_vm._v("Back\n        ")],1)],1),_c('h2',[_vm._v("Manage address book")])]),_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"manageAddressItems"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]},_vm._l((3),function(n){return _c('div',{key:n,staticClass:"addressItem"},[_c('div',{staticClass:"addressInner"},[_c('div',{staticClass:"addressMiddle"},[_c('h3',[_c('q-skeleton',{attrs:{"type":"rect","width":"140px"}})],1),_c('p',[_c('q-skeleton',{attrs:{"type":"text","width":"100%"}}),_c('q-skeleton',{attrs:{"type":"text","width":"30%"}})],1),_c('p',[_c('q-skeleton',{staticClass:"q-mt-lg",attrs:{"type":"rect","width":"50%"}})],1)]),_c('div',{staticClass:"addressBottom"},[_c('a',{attrs:{"href":"javascript:void(0);"}},[_c('q-skeleton',{attrs:{"type":"rect","width":"100%"}})],1),_c('a',{attrs:{"href":"javascript:void(0);"}},[_c('q-skeleton',{attrs:{"type":"rect","width":"100%"}})],1)])])])}),0),_c('transition-group',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"appear":"","leave-active-class":"animated fadeOutUp","enter-active-class":"animated fadeIn"}},_vm._l((_vm.addressList),function(address,key){return _c('div',{key:`${key}`,class:[
              'addressItem',
              key == 'add-address' ? 'addAddressItem' : '',
            ]},[_c('div',{staticClass:"addressInner"},[(key == 'add-address')?_c('a',{staticClass:"addressMiddle",attrs:{"href":"javascript:void('0');"},on:{"click":function($event){return _vm.editAddress()}}},[_c('SvgIcon',{staticClass:"q-mr-sm",attrs:{"size":"16px","icon":"plus"}}),_vm._v("Add New\n                Address\n              ")],1):[_c('div',{staticClass:"addressMiddle"},[_c('h3',[_vm._v("\n                    "+_vm._s(`${address.firstName} ${address.lastName}`)+"\n                    "),_c('transition',{attrs:{"appear":"","enter-active-class":"animated fadeIn","leave-active-class":"animated fadeOut"}},[(address.isDefault)?_c('span',[_vm._v("Default")]):_vm._e()])],1),_c('p',{staticClass:"addressItem-address",domProps:{"innerHTML":_vm._s(_vm.$options.filters.formatAddress(address))}}),(!address.isDefault)?_c('transition',{staticClass:"addressSwitch",attrs:{"appear":"","enter-active-class":"animated fadeIn","leave-active-class":"animated fadeOut"}},[_c('q-toggle',{staticClass:"toggle",attrs:{"size":"xs","value":address.isDefault,"label":"Set as Default address"},on:{"input":function($event){return _vm.setAsDefault(address)}}})],1):_vm._e(),(
                      _vm.selectedFulfillmentCodeName == 'scd' &&
                      address.isDeliver === false
                    )?_c('q-item-label',{staticClass:"text-red bg-red-1 dotted-border-red q-mt-sm text-body2 q-pa-sm rounded-borders"},[_vm._v("\n                    *Unable to deliver to this location for Delivery\n                  ")]):(
                      _vm.selectedFulfillmentCodeName == 'sd' &&
                      address.isShippingPossible === false
                    )?_c('q-item-label',{staticClass:"text-red bg-red-1 dotted-border-red q-mt-sm text-body2 q-pa-sm rounded-borders"},[_vm._v("\n                    *Unable to ship to this location for Standard Shipping\n                  ")]):_vm._e()],1),_c('div',{staticClass:"addressBottom"},[_c('a',{staticClass:"edit",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.editAddress(address)}}},[_c('SvgIcon',{staticClass:"q-mr-sm",attrs:{"size":"11px","icon":"edit"}}),_vm._v("Edit\n                  ")],1),_c('q-btn',{staticClass:"delete",class:{
                      disable: address.isDefault,
                    },staticStyle:{"color":"#ec7a7a","border-left":"1px solid #ccc"},attrs:{"flat":"","loading":_vm.showLoading(address.addressId)},on:{"click":function($event){return _vm.deleteAddress(address)}}},[_c('SvgIcon',{staticClass:"q-mr-sm",attrs:{"size":"11px","icon":"trash"}}),_vm._v("\n                    Delete\n                  ")],1)],1)]],2)])}),0)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }