<template>
  <q-page class="accountPages">
    <div class="container">
      <div class="page-title hide-in-mobile">
        <div class="page-breadcrumb">
          <router-link to="/account">
            <SvgIcon icon="back-arrow-line" />Back
          </router-link>
        </div>
        <h2>Manage address book</h2>
      </div>
      <div class="page-content">
        <div class="manageAddressItems">
          <!-- Loader -->
          <span v-show="loading">
            <div class="addressItem" v-for="n in 3" :key="n">
              <div class="addressInner">
                <div class="addressMiddle">
                  <h3><q-skeleton type="rect" width="140px" /></h3>
                  <p>
                    <q-skeleton type="text" width="100%" />
                    <q-skeleton type="text" width="30%" />
                  </p>
                  <p>
                    <q-skeleton type="rect" width="50%" class="q-mt-lg" />
                  </p>
                </div>
                <div class="addressBottom">
                  <a href="javascript:void(0);">
                    <q-skeleton type="rect" width="100%" />
                  </a>
                  <a href="javascript:void(0);">
                    <q-skeleton type="rect" width="100%" />
                  </a>
                </div>
              </div>
            </div>
          </span>
          <!-- Loader -->
          <transition-group
            v-show="!loading"
            appear
            leave-active-class="animated fadeOutUp"
            enter-active-class="animated fadeIn"
          >
            <div
              v-for="(address, key) in addressList"
              :key="`${key}`"
              :class="[
                'addressItem',
                key == 'add-address' ? 'addAddressItem' : '',
              ]"
            >
              <div class="addressInner">
                <a
                  v-if="key == 'add-address'"
                  href="javascript:void('0');"
                  class="addressMiddle"
                  @click="editAddress()"
                >
                  <SvgIcon size="16px" icon="plus" class="q-mr-sm" />Add New
                  Address
                </a>
                <template v-else>
                  <div class="addressMiddle">
                    <!-- <a href="javascript:void(0)" class="quickAction active">
                      <SvgIcon icon="arrow-down" />
                    </a> -->
                    <h3>
                      {{ `${address.firstName} ${address.lastName}` }}
                      <transition
                        appear
                        enter-active-class="animated fadeIn"
                        leave-active-class="animated fadeOut"
                      >
                        <span v-if="address.isDefault">Default</span>
                      </transition>
                    </h3>
                    <p
                      class="addressItem-address"
                      v-html="$options.filters.formatAddress(address)"
                    />

                    <transition
                      appear
                      enter-active-class="animated fadeIn"
                      leave-active-class="animated fadeOut"
                      class="addressSwitch"
                      v-if="!address.isDefault"
                    >
                      <q-toggle
                        size="xs"
                        :value="address.isDefault"
                        @input="setAsDefault(address)"
                        label="Set as Default address"
                        class="toggle"
                      />
                    </transition>

                    <q-item-label
                      v-if="
                        selectedFulfillmentCodeName == 'scd' &&
                        address.isDeliver === false
                      "
                      class="text-red bg-red-1 dotted-border-red q-mt-sm text-body2 q-pa-sm rounded-borders"
                    >
                      *Unable to deliver to this location for Delivery
                    </q-item-label>
                    <q-item-label
                      v-else-if="
                        selectedFulfillmentCodeName == 'sd' &&
                        address.isShippingPossible === false
                      "
                      class="text-red bg-red-1 dotted-border-red q-mt-sm text-body2 q-pa-sm rounded-borders"
                    >
                      *Unable to ship to this location for Standard Shipping
                    </q-item-label>
                  </div>
                  <div class="addressBottom">
                    <a
                      href="javascript:void(0);"
                      class="edit"
                      @click="editAddress(address)"
                    >
                      <SvgIcon size="11px" icon="edit" class="q-mr-sm" />Edit
                    </a>
                    <q-btn
                      flat
                      class="delete"
                      :class="{
                        disable: address.isDefault,
                      }"
                      :loading="showLoading(address.addressId)"
                      style="color: #ec7a7a; border-left: 1px solid #ccc"
                      @click="deleteAddress(address)"
                    >
                      <SvgIcon size="11px" icon="trash" class="q-mr-sm" />
                      Delete
                    </q-btn>
                  </div>
                </template>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import { mergeObjects } from 'src/utils'

export default {
  name: 'PageAddress',
  computed: {
    ...mapGetters('address', ['addresses', 'loading']),
    ...mapGetters('persisted', ['selectedFulfillmentCodeName']),
    addressList() {
      if (this.loading) return {}

      return mergeObjects({ 'add-address': {} }, this.addresses)
    },
  },
  meta() {
    return {
      title: 'Address',
      ...this.commonMeta,
    }
  },
  methods: {
    editAddress(address = {}) {
      this.changeDialogState({
        dialog: 'addShippingAddress',
        val: true,
        properties: {
          address,
        },
      })
    },
    showLoading(addrssId) {
      return this.trashLoading[addrssId] || false
    },
    deleteAddress(address) {
      this.ftxConfirm('Are you sure you want to delete Address?').onOk(
        async () => {
          this.$set(this.trashLoading, address.addressId, true)

          this.$store
            .dispatch('address/deleteAddress', address)
            .then((response) => {
              if (response) {
                this.showSuccess('Your Address Removed successfully.')
              }
            })
            .finally(() => {
              this.$set(this.trashLoading, address.addressId, false)
            })
        }
      )
    },
    async setAsDefault(address) {
      this.$store
        .dispatch('address/UpdateDefaultAddress', address)
        .then((response) => {
          this.showSuccess('Default address modified.')
        })
    },
  },
  data() {
    return {
      addressDefault: false,
      trashLoading: {},
    }
  },
  destroyed() {
    this.$store.dispatch('address/getCustomerAddresses')
  },
}
</script>
<style lang="scss">
.manageAddressItems {
  > span {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    margin-left: -10px;
    margin-right: -10px;
  }
  .addressItem {
    padding: 10px;
    flex: 0 0 100%;
    @media (min-width: 600px) {
      flex: 0 0 50%;
    }
    @media (min-width: 1024px) {
      flex: 0 0 33.33%;
    }
    .addressInner {
      display: flex;
      flex-direction: column;
      position: relative;
      border: 1px solid #cccccc;
      height: 100%;
      min-height: 173px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
    }
    .addressMiddle {
      padding: 20px;
      flex: 1 0 0;
      h3 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        color: #314154;
        min-height: 21px;
        span {
          display: inline-block;
          vertical-align: middle;
          background: #91bf5b;
          margin-left: 5px;
          padding: 3px 15px;
          font-size: 10px;
          color: #fff;
          border-radius: 10px;
        }
        @media (min-width: 1681px) {
          font-size: 20px;
        }
      }
      p {
        margin: 10px 0 0;
        padding: 0;
        font-size: 13px;
        line-height: 1.3;
        color: #888888;
        @media (min-width: 1681px) {
          font-size: 16px;
        }
      }
      .toggle {
        margin-top: 15px;
        .q-toggle__label {
          font-size: 12px;
          color: #0f141a;
        }
      }
    }
    .quickAction {
      display: block;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 20px;
      color: #888888;
      background: #eeeeee;
      position: absolute;
      right: 20px;
      top: 20px;
      border-radius: 50%;
      svg {
        width: 10px;
        height: 20px;
        transition: transform 0.3s ease-in-out;
      }
      &.active {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
    .addressSwitch {
      margin-top: 10px;
      min-height: 15px;
      font-size: 13px;
      color: #0f141a;
    }
    .addressBottom {
      border-top: 1px solid #cccccc;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      a,
      button {
        flex: 1 0 50%;
        border-radius: 0;
      }
      a {
        padding: 10px;
        font-size: 14px;
        text-align: center;
        text-decoration: none;
        border-left: 1px solid #ccc;
        @media (min-width: 1681px) {
          padding: 15px;
        }
        &:first-child {
          border-left: none;
        }
        svg {
          width: 20px;
          height: 20px;
        }
        &.edit {
          color: $primaryOnBody;
          color: var(--q-color-primaryOnBody) !important;
        }

        &.disable {
          color: #888888;
          pointer-events: none;
        }
      }
    }
    &.addAddressItem {
      .addressInner {
        text-align: center;
        background: #f2fbff;
        background: var(--q-color-light-primary);
        border-color: #99c2d2;
        border-color: var(--q-color-primary);
        @media (max-width: 767px) {
          min-height: 60px;
        }
      }
      .addressMiddle {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $tertiary;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
    .addressItem-address {
      min-height: 83px;
    }
  }
}
</style>
